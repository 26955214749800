body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url("https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg") center;
}
.banner1 .bg1 {
  background: url("https://zos.alipayobjects.com/rmsportal/xHxWkcvaIcuAdQl.jpg") center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 550px;
  text-align: center;
}
.banner1 .banner1-title {
  width: 350px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content10-wrapper {
  height: 480px;
  background: url(https://gw.alipayobjects.com/os/s/prod/seeconf/c66ebea962cdf544926ca5a472dea5ea.jpg) no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  animation: ScaleAnim 2s ease-in-out infinite;
  transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(-7px);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.9);
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdt5xi8bp-editor_css {
  font-size: 24px;
}
#Content0_0 .ant-col > .content0-block-item > .lsdukw06fjf-editor_css {
  width: 200px;
  height: 180px;
}
#Content0_0 .ant-col > .content0-block-item > .lsdunkyct-editor_css {
  width: 200px;
  height: 180px;
  clear: both;
}
#Content0_0 .ant-col > .content0-block-item > .lsdupwfb4ys-editor_css {
  width: 200px;
  height: 180px;
  clear: both;
}
#Feature0_0 .ant-col > .content0-block-item > .lsdv9khc14-editor_css {
  width: 200px;
  height: 180px;
}
#Feature0_0 .ant-col > .content0-block-item > .lsdvabww877-editor_css {
  width: 200px;
  height: 180px;
}
#Feature0_0 .ant-col > .content0-block-item > .lsdvb0qsdr6-editor_css {
  width: 200px;
  height: 180px;
}
#Teams1_0 div > .ant-row > .lsdwfrjupqr-editor_css {
  opacity: 0.2;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .lsdsvcdyr-editor_css {
  background-image: url(../../static/media/sport1.8d133aec.jpeg);
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdx6lf3lp-editor_css {
  width: 300px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdx89v0sur-editor_css {
  border-width: 0px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdxddeqby-editor_css {
  top: -40px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdxf3qfq8-editor_css {
  border-width: 0px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdxm80dtxo-editor_css {
  border-width: 0px;
}
#Content1_0 .ant-row > .ant-col > .lsdvcu2gg1-editor_css {
  width: 350px;
}
@media screen and (max-width: 767px) {
  #Content1_0 .ant-row > .ant-col > .lsdvcu2gg1-editor_css {
    width: 17.5em;
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .lsds03di2h-editor_css {
  display: inline-block;
  color: rgba(255, 255, 255, 0.78);
  clear: left;
  background-color: rgba(34, 34, 34, 0.61);
  background-image: url(../../static/media/sport1.8d133aec.jpeg);
  background-position: 0% 0%;
  background-clip: content-box, padding-box;
  margin: 0px 0px;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .lsdskd2j3qs-editor_css {
  align-items: flex-start;
  justify-content: space-between;
  opacity: 0.94;
  background-image: url(../../static/media/sport1.8d133aec.jpeg);
}
#Content0_0 .lse4wodt2jr-editor_css {
  background-color: rgba(125, 0, 253, 0);
}
#Feature0_0.lse9i84y1pp-editor_css {
  background-color: #7265e6;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsdt77x0hqu-editor_css {
  font-size: 24px;
  font-weight: 500;
  color: #e2e2e2;
  margin: -40px 0px 20px;
}
#Teams1_0 .home-page > .title-wrapper > .lse9ts0z8fg-editor_css {
  color: #404040;
}
#Contact0_0.lse9v5o2dzq-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Footer1_1 .copyright-wrapper > .home-page > .lse9olbki3t-editor_css {
  color: #404040;
}
#Teams1_0 .lse9mvkaknk-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Content0_0.lse9gdydjhh-editor_css {
  background-color: rgba(194, 194, 194, 0.19);
}
#Content1_0.lse9ifqqqrg-editor_css {
  background-color: rgba(194, 194, 194, 0.19);
}
#Teams1_0.lse9mwpommr-editor_css {
  background-color: rgba(194, 194, 194, 0.19);
}
#Feature0_0 .home-page > .title-wrapper > .lse9j7an93t-editor_css {
  color: #222222;
}
@media screen and (max-width: 767px) {
  #Feature0_0 .home-page > .title-wrapper > .lse9j7an93t-editor_css {
    color: #e9e9e9;
  }
}
#Footer1_1.lse9nr45p1-editor_css {
  background-color: #7265e6;
}
#Footer1_1 .ant-row > .ant-col > .lse9qp0ncji-editor_css {
  color: #fbfbfb;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-row > .ant-col > .lse9qp0ncji-editor_css {
    color: #fbfbfb;
  }
}
#Footer1_1 .ant-row > .ant-col > .lse9r3577p6-editor_css {
  color: #fbfbfb;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-row > .ant-col > .lse9r3577p6-editor_css {
    color: #fbfbfb;
  }
}
#Footer1_1 .ant-col > div > .lse9qw44vin-editor_css {
  color: #e9e9e9;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > div > .lse9qw44vin-editor_css {
    color: #d9d9d9;
  }
}
#Footer1_1 .ant-col > div > .lse9r0ymc08-editor_css {
  color: #e9e9e9;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > div > .lse9r0ymc08-editor_css {
    color: #d9d9d9;
  }
}
#Footer1_1 .ant-col > .slogan > .lse9qjtpcp7-editor_css {
  color: #fbfbfb;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > .slogan > .lse9qjtpcp7-editor_css {
    color: #fbfbfb;
  }
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsds26vc8j-editor_css {
  font-family: Impact;
  font-size: 7rem;
  font-weight: 500;
  width: 30rem;
  max-height: 5rem;
  background-color: rgba(0, 0, 0, 0);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsds26vc8j-editor_css {
    font-family: Impact;
    font-size: 4rem;
    font-weight: 500;
    width: 5.5em;
    max-height: 5rem;
    top: 6rem;
    background-color: rgba(0, 0, 0, 0);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 5px rgba(73, 20, 20, 0.78);
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .lsdsmw2eiw-editor_css {
  text-align: left;
  max-height: 10rem;
  top: 26%;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim > .banner-anim-elem > .lsdsmw2eiw-editor_css {
    font-size: 10rem;
    top: 25%;
  }
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsds1t8bohi-editor_css {
  font-family: Impact;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(243, 243, 243, 0.98);
  position: relative;
  margin: -10rem 0px 10px;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .lsds1t8bohi-editor_css {
    font-family: Impact;
    font-size: 2.5rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    margin: -2rem 0px 50px;
  }
}
#Teams1_0 .ant-row > .ant-col > .lspn1slesy9-editor_css {
  width: 300px;
}
#Teams1_0 .ant-row > .ant-col > .lsppf4h61nh-editor_css {
  width: 300px;
}

